import * as React from "react";
import Api from "src/api";
import DateInput from "src/components/DateInput";
import Input from "src/components/Input";
import TextArea from "src/components/TextArea";

type Props = {
  name: string;
  initValue?: string | Date;
  placeholder: string;
  type?: string;
  validator?(value: string): boolean;
  handleSubmit(): void;
};

type State = {
  value?: string;
  error?: string;
};

class InPlaceEditInputContainer extends React.Component<Props, State> {
  public state = {
    value: this.props.initValue,
    error: null,
  };

  public render() {
    if (this.props.type === "date") {
      return (
        <DateInput
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          placeholder={this.props.placeholder}
          value={this.state.value}
        />
      );
    }

    if (this.props.type === "text-area") {
      return (
        <TextArea
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          placeholder={this.props.placeholder}
          value={this.state.value}
        />
      );
    }

    return (
      <Input
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        placeholder={this.props.placeholder}
        value={this.state.value}
        error={this.state.error}
      />
    );
  }

  public sendRequest = async (value: string | Date) => {
    try {
      const { data } = await Api.updateUserField({
        field: this.props.name,
        value: value,
      });
      this.setState({ value: data[this.props.name], error: "" });
    } catch (e) {
      this.setState({ error: e.message });
    }
    this.props.handleSubmit();
    return this.state.value;
  };

  private handleBlur = async (date: Date) => {
    if (!this.state.value && this.props.type !== "date") return;
    if (
      this.state.value === this.props.initValue &&
      this.props.type !== "date"
    ) {
      return;
    }

    if (this.props.validator) {
      const isValid = this.props.validator(this.state.value);
      if (!isValid) {
        this.setState({ value: "", error: "неверные данные" });
        return;
      }
    }

    let value: string | Date = this.state.value;
    if (this.props.type === "date") {
      value = date;
      this.setState({ value });
    }

    await this.sendRequest(value);
    return this.state.value;
  };

  private handleChange = (
    ev:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ value: ev.currentTarget.value });
    return ev.currentTarget?.value;
  };
}

export default InPlaceEditInputContainer;
