import React from "react";
import redTimes from "src/images/red-times.svg";
// tslint:disable-next-line: no-relative-imports
import styles from "./Textarea.module.css";

type Props = {
  value?: string | number;
  error?: boolean;
  placeholder: string;
  wrapperClassName?: string;
  rows?: number;
  black?: boolean;
  handleBlur?(
    ev: React.ChangeEvent<HTMLTextAreaElement>
  ): string | Promise<string>;
  handleChange(ev: React.ChangeEvent<HTMLTextAreaElement>): string;
};

const Input = (props: Props) => {
  return (
    <div
      className={`${styles.inputWrapper} ${props.wrapperClassName || ""} ${
        props.black ? styles.black : ""
      }`}
    >
      <textarea
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.value}
        placeholder={props.placeholder}
        className={styles.input}
        rows={props.rows}
      />
      {props.error ? <img src={redTimes} alt="ошибка" /> : ""}
    </div>
  );
};

export default Input;
