import * as React from "react";
import { connect } from "react-redux";
import InPlaceEditInputContainer from "src/components/InPlaceEditInputContainer";
import eyeIcon from "src/images/eye.svg";
import { actions as userActions } from "src/store/User";
import { User as UserType } from "src/types";
import { isValidURL } from "src/utils/validatiors";
import styles from "./user.module.css";

interface Props extends ConnectStateProps, ConnectDispatchProps {
  className?: string;
  children: React.ReactNode;
}

class User extends React.Component<Props> {
  public componentDidMount() {
    this.props.getUser();
  }

  public handleSubmit = () => {
    this.props.getUser();
  };

  public render() {
    return (
      <div className={styles.userPageWrapper}>
        <div className={styles.imageWrapper}>
          <img src={eyeIcon} alt="иконка глаза" />
        </div>
        <div className={styles.inputsWrapper}>
          <div className={styles.twoSections}>
            <div>
              <InPlaceEditInputContainer
                name="email"
                placeholder="Email"
                initValue={this.props.user?.email}
                handleSubmit={this.handleSubmit}
              />
              <InPlaceEditInputContainer
                name="lastName"
                placeholder="Фамилия"
                initValue={this.props.user?.lastName}
                handleSubmit={this.handleSubmit}
              />
              <InPlaceEditInputContainer
                name="name"
                placeholder="Имя"
                initValue={this.props.user?.name}
                handleSubmit={this.handleSubmit}
              />
              <InPlaceEditInputContainer
                name="fatherName"
                placeholder="Отчество"
                initValue={this.props.user?.fatherName}
                handleSubmit={this.handleSubmit}
              />

            </div>
            <div>
              <InPlaceEditInputContainer
                name="dateOfBirth"
                placeholder="Дата Рождения"
                type="date"
                initValue={this.props.user?.dateOfBirth}
                handleSubmit={this.handleSubmit}
              />
              <InPlaceEditInputContainer
                name="city"
                placeholder="Город"
                initValue={this.props.user?.city}
                handleSubmit={this.handleSubmit}
              />
              <InPlaceEditInputContainer
                  name="phoneNumber"
                  placeholder="Номер телефона"
                  initValue={this.props.user?.phoneNumber}
                  handleSubmit={this.handleSubmit}
              />
              <InPlaceEditInputContainer
                name="socialLink"
                placeholder="Ссылка на социальную сеть"
                initValue={this.props.user?.socialLink}
                handleSubmit={this.handleSubmit}
                validator={isValidURL}
              />
            </div>
          </div>
          <div>
            <InPlaceEditInputContainer
              name="about"
              placeholder="О себе"
              type="text-area"
              initValue={this.props.user?.about}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

interface ConnectStateProps {
  user: UserType;
}

interface ConnectDispatchProps {
  getUser(): typeof userActions.getUser;
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
});

const mapActionsToProps = {
  getUser: userActions.getUser,
};

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(User);
